// Подсчёт рулонов
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { List, ListItem, ListItemText, Skeleton, ListItemAvatar, Avatar } from '@mui/material';

import { Breadcrumbs, Link } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

import ImageIcon from '@mui/icons-material/Image';

import FS from './fs';
import Models from './Models';
import Data_Folders from './Data_folders'


import Select from '../Wrappers/Select'
import Loading from '../Wrappers/Loading';

import Demo from './demo.jpg'; // Импортируем картинку

const apiHost = process.env.REACT_APP_API_HOST;

function NeuroVision() {
    const [path, setPath] = useState('');
    const [model, setModel] = useState('');
    const [data, setData] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState(null); // Добавил состояние для ошибок

    useEffect(() => {
        const fetchFiles = async () => {
            if (path && model) { // Проверяем, что path и model заданы
                try {

                    const response = await fetch(`${apiHost}/run/?path=${path}&model=${model}`);
                    if (response.ok) {
                        const data = await response.json();
                        console.log(data);


                        setData(data);
                    } else {
                        console.error('Error fetching files:', response.statusText);
                        setError('Ошибка загрузки данных'); // Устанавливаем сообщение об ошибке

                    }
                } catch (error) {
                    console.error("Error fetching files:", error);
                    setError('Ошибка сети или сервера'); // Сообщение об ошибке сети
                }
            }
            else {
                setIsDisabled(false)
                console.log('path && model no select');
            }

        }
        fetchFiles();
    }, [path, model]);

    const handleClick = () => {
        const savedPath = localStorage.getItem('path');
        const savedModel = localStorage.getItem('model');
        setPath(savedPath);
        setModel(savedModel);
    }

    // const item.image = 'demo.lpg'

    return (
        <>
            <Typography variant="h5" gutterBottom>
                Нейрозрение <br/> Сервис не запущен!
            </Typography>
            {/* <Loading apiHost={apiHost} url={'models'}>
                <Select />
            </Loading>
            <Loading apiHost={apiHost} url={'folders'}>
                <Data_Folders />
            </Loading>

            <Button
                onClick={handleClick}
                disabled={isDisabled}
            >
                Запуск
            </Button>

            {error && <Typography color="error">{error}</Typography>} 
            {data && <div>{JSON.stringify(data)}</div>}  */}

            <Box
                component="img"
                sx={{
                    // height: 600,
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                }}
                alt="Демо картинка"
                src={Demo} // Используем импортированное изображение
            />
        </>
    );
}

export default NeuroVision

function Photo() {
    const apiHost = process.env.REACT_APP_API_HOST;
    const [data, setData] = useState(null);

    useEffect(() => {
        // Функция для загрузки данных с сервера
        const fetchDataFromServer = async () => {
            try {
                const response = await fetch(`${apiHost}/photos`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataFromServer();
    }, [apiHost]);

    return (
        <>
            {/* <Typography sx={{ color: "#e60c0c" }}>
                Всего снимков: {data?.length}
            </Typography> */}
            <List dense={true} sx={{ width: '100%', maxWidth: 240, bgcolor: 'background.paper' }}>
                {data ? (
                    data.map((row) => (
                        <ListItem>
                            <ListItemText primary={row?.name} />
                            <ImageIcon />
                        </ListItem>

                    )))
                    :
                    (
                        <>
                            <ListItem>
                                <Skeleton width={240} />
                            </ListItem>
                            <ListItem>
                                <Skeleton width={240} />
                            </ListItem>
                            <ListItem>
                                <Skeleton width={240} />
                            </ListItem>
                        </>
                    )}

            </List>
        </>
    )
}

