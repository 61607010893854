// Домашняя страница Агроаналитики

// 'Главная', 'Сводка', 'Расчет хим.состава', 'Подсчёт рулонов', 'Карта урожайности', 'Карта сорняков', 'Настройки'

import React from 'react';
import { AppBar, Toolbar, Typography, CssBaseline, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Импортируем компоненты основного окна
import Fertilizer from './Fertilizer/Fertilizer';
import NeuroVision from './NeuroVision/NeuroVision';
import Settings from './Settings'
import Menu from './Menu';
import Efis from './Efis/Efis';

function App() {
    return (
        <Router>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                {/* Шапка */}
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <Menu />
                        <Typography variant="h6" noWrap>
                            Агроаналитика
                        </Typography>
                    </Toolbar>
                </AppBar>

                {/* Основное содержимое страницы */}
                <Box
                    component="main"
                    sx={{ flexGrow: 1, bgcolor: '#666', p: 1, width: '100vh' }}
                >
                    <Toolbar />
                    {/* Центрует положение */}
                    {/* <Container maxWidth="lg"> */}
                    <Routes basename="/home">
                        <Route path="/" element={<Home />} />
                        <Route path="/fertilizer" element={<Fertilizer />} />
                        <Route path="/neurovision" element={<NeuroVision />} />
                        <Route path="/efis" element={<Efis />} />
                        <Route path="/settings" element={<Settings />} />
                    </Routes>
                    {/* </Container> */}
                </Box>
            </Box>
        </Router>
    );
}

// Компоненты страниц

function Home() {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                Сводка данных
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="rectangular" width={300} height={200} />
            </Box>
        </>
    );
}

export default App;