import React, { useState } from 'react';
import { Drawer, Toolbar, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const drawerWidth = 180;

function Menu() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem button component={Link} to="/">
                            <ListItemText primary="Главная" />
                        </ListItem>
                        <ListItem button component={Link} to="/neurovision">
                            <ListItemText primary="Нейрозрение" />
                        </ListItem>
                        {/* <ListItem button component={Link} to="/fertilizer">
                            <ListItemText primary="Удобрения" />
                        </ListItem>
                        <ListItem button component={Link} to="/weed">
                            <ListItemText primary="Сорняки" />
                        </ListItem> */}
                        <ListItem button component={Link} to="/efis">
                            <ListItemText primary="ЕФИС ЗСН" />
                        </ListItem>
                        {/* <ListItem button component={Link} to="/harvest">
                            <ListItemText primary="Урожай" />
                        </ListItem> */}
                        <ListItem button component={Link} to="/settings">
                            <ListItemText primary="Настройки" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    );
}

export default Menu;
