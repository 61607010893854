import { React, useState } from 'react';
import { Box, Typography, Button } from '@mui/material/';
import axios from 'axios';
import { json } from 'react-router-dom';
import { nil } from 'ajv';

const apiHost = process.env.REACT_APP_API_HOST;

export default function Efis() {
    const [url, setUrl] = useState('');


    const handleItemClick = (event) => {
        console.log('test:');
    };

    return (
        <>
            <Box
                sx={{
                    minHeight: 250,
                    minWidth: 250,
                    maxWidth: 250,
                    background: '#aaa',
                    borderRadius: 1,
                }}
            >
                <Typography variant="subtitle1" component="div">ЕФИС ЗСН</Typography>
                <FileUpload />

            </Box>

        </>
    );
}


const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uid, setUid] = useState('');
    const [status, setStatus] = useState('');


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setStatus(null)
    };
    const handleUploadClick = async () => {
        "Загрузить"
        if (selectedFile) {

            const formData = new FormData();
            formData.append('file', selectedFile); // Добавляем файл в FormData

            try {
                const response = await fetch(`${apiHost}/upload`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    console.log('Файл успешно загружен');
                    const data = await response.json()
                    console.log(data);
                    setStatus('Файл успешно загружен и обработан')

                    setUid(data)
                } else {
                    console.error('Ошибка при загрузке файла');
                    setStatus(`Ошибка при загрузке файла ${response.code}`)
                }
            } catch (error) {
                setStatus('Ошибка сети:', error)
                console.error('Ошибка сети:', error);
            }

        } else {
            console.log("Файл не выбран");
            setStatus("Файл не выбран")
        }
    };

    return (
        <div>
            <input
                accept=".zip,application/geo+json" // Ограничение только на формат
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: 'none' }} // Скрываем input
                onChange={handleFileChange}
            />



            <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                    Выбрать файл
                </Button>
            </label>

            <br />
            {selectedFile ? <div>Выбран файл:<br /> {selectedFile?.name} </div>: <div> </div> }
            
            <br />

            {status}

            <br />
            <br />
            <Button
                variant="contained"
                color="primary"
                onClick={handleUploadClick}
            >
                Загрузить
            </Button>
            <br />
            <br />
            <DownloadButton uid={uid} /><br />

        </div>
    );
};

// export default FileUpload;

function DownloadButton({ uid }) {

    const downloadGeoJSON = () => {
        axios({
            url: `${apiHost}/download/${uid}`, // URL на ваш FastAPI бэкенд
            method: 'GET',
            responseType: 'blob', // Важно для работы с файлами
        }).then((response) => {
            // Создаем ссылку для скачивания
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${uid}.zip`); // Имя файла
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            console.error("Ошибка при скачивании файла: ", error);
        });
    };

    let uid_stat = uid ?  false : true
    console.log(uid_stat);

    return (

        <Button variant="contained" disabled={uid_stat} onClick={downloadGeoJSON}>Скачать</Button>
    );
}
